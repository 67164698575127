import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 2339.000000 1654.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1654.000000) scale(0.100000,-0.100000)">
          
          <path d="M10320 10236 c0 -2 182 -462 405 -1023 245 -614 419 -1038 439 -1069
157 -236 462 -332 724 -227 273 108 428 392 368 673 -8 36 -105 295 -216 575
-111 281 -205 516 -207 523 -9 23 -62 44 -94 37 -34 -8 -69 -48 -69 -81 0 -12
93 -256 206 -541 193 -486 206 -523 211 -598 15 -225 -131 -408 -356 -447
-190 -32 -384 81 -461 268 -17 44 -194 491 -394 994 l-362 915 -97 3 c-53 1
-97 1 -97 -2z"/>
<path d="M10882 10223 c3 -10 164 -418 357 -908 220 -556 360 -897 373 -907
30 -26 90 -23 115 5 11 12 24 32 27 44 5 16 -51 169 -198 540 -113 285 -209
544 -215 575 -22 122 28 270 122 362 97 94 238 141 358 118 129 -24 257 -111
307 -208 11 -22 97 -235 192 -474 94 -239 175 -441 179 -448 6 -11 541 1234
558 1298 5 20 0 20 -306 20 -171 0 -311 -3 -311 -8 0 -4 62 -163 139 -354
l138 -346 -70 -167 -70 -167 -143 358 c-156 391 -194 460 -291 539 -80 65
-184 113 -283 131 -247 43 -499 -75 -621 -291 l-32 -58 -71 179 -71 179 -94 3
c-86 3 -93 1 -89 -15z"/>
<path d="M9295 7275 c-115 -41 -160 -193 -82 -281 36 -42 74 -62 187 -99 64
-21 94 -38 124 -67 37 -37 38 -40 34 -93 -3 -40 -11 -62 -28 -80 -71 -76 -218
-48 -261 50 -18 41 -23 45 -54 45 -30 0 -35 -3 -35 -23 0 -41 53 -110 107
-139 125 -68 276 -36 331 72 26 52 28 106 7 157 -29 68 -74 99 -210 143 -119
38 -165 79 -165 145 0 81 62 131 155 123 62 -5 106 -33 129 -83 15 -30 22 -35
51 -35 42 0 45 17 12 75 -51 90 -187 131 -302 90z"/>
<path d="M12362 7268 c3 -7 61 -169 129 -360 l124 -348 76 0 76 0 127 353 c69
193 126 356 126 360 0 4 -28 7 -62 5 l-62 -3 -100 -287 c-55 -159 -103 -288
-106 -288 -3 0 -50 131 -105 290 l-100 290 -64 0 c-46 0 -62 -3 -59 -12z"/>
<path d="M13050 7235 l0 -45 100 0 100 0 0 -315 0 -315 60 0 60 0 2 313 3 312
103 3 102 3 0 44 0 45 -265 0 -265 0 0 -45z"/>
<path d="M13652 7023 c4 -243 5 -261 27 -310 99 -225 448 -210 516 21 12 38
15 108 15 298 l0 248 -60 0 -60 0 0 -245 c0 -201 -3 -252 -16 -285 -22 -54
-70 -83 -139 -83 -67 0 -111 25 -139 78 -19 37 -21 59 -24 288 l-4 247 -60 0
-60 0 4 -257z"/>
<path d="M10005 7121 c-68 -18 -116 -56 -148 -116 -16 -30 -16 -30 -17 43 l0
72 -35 0 -35 0 0 -415 0 -415 35 0 35 0 0 208 c0 194 1 205 16 177 91 -175
362 -159 445 27 33 74 33 201 0 274 -26 60 -71 107 -123 128 -45 19 -133 28
-173 17z m162 -93 c54 -36 76 -85 81 -180 5 -97 -19 -155 -81 -196 -35 -23
-51 -27 -117 -27 -67 0 -82 4 -118 28 -96 63 -120 223 -50 327 13 19 43 44 68
58 62 33 159 29 217 -10z"/>
<path d="M10627 7119 c-81 -19 -131 -62 -169 -144 -19 -41 -23 -67 -23 -140 1
-77 5 -97 27 -141 35 -67 86 -110 155 -130 119 -35 235 10 287 111 16 30 16
29 16 -42 l0 -73 35 0 35 0 0 280 0 280 -35 0 -35 0 0 -72 -1 -73 -19 36 c-27
50 -72 86 -132 104 -57 17 -84 17 -141 4z m184 -82 c29 -16 54 -40 74 -72 27
-43 30 -57 30 -124 -1 -93 -26 -148 -87 -188 -36 -24 -51 -28 -118 -28 -66 0
-82 4 -117 27 -63 41 -86 99 -82 202 3 69 8 89 31 123 53 81 177 108 269 60z"/>
<path d="M11307 7116 c-75 -21 -127 -63 -162 -131 -27 -53 -30 -68 -30 -149 1
-96 10 -124 62 -194 74 -99 270 -119 376 -38 46 35 87 98 87 134 0 18 -5 22
-32 22 -29 0 -35 -5 -49 -39 -9 -21 -33 -51 -54 -67 -35 -27 -45 -29 -120 -29
-75 0 -85 2 -119 29 -58 44 -79 100 -74 200 2 70 7 89 31 125 79 120 295 103
337 -25 10 -31 15 -34 50 -34 l39 0 -10 33 c-35 125 -193 202 -332 163z"/>
<path d="M11947 7116 c-75 -21 -127 -63 -162 -131 -27 -53 -30 -68 -30 -149 1
-68 5 -100 19 -126 34 -64 67 -100 115 -125 134 -67 302 -26 368 90 33 57 30
75 -10 75 -28 0 -35 -6 -55 -43 -32 -60 -87 -90 -167 -91 -79 -1 -131 26 -167
85 -14 24 -29 61 -32 81 l-7 38 233 2 233 3 3 30 c6 49 -17 129 -49 173 -57
77 -189 117 -292 88z m173 -71 c51 -27 84 -75 95 -142 l7 -43 -202 0 -201 0 7
38 c14 82 70 143 148 163 38 10 112 2 146 -16z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
